<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="container-fluid">
      <div class="container-standar">
        <div class="row">
          <div class="col-md-12 pt-2  mt-4 pb-3">
            <router-link to="/"><a href="#" class="text-primary font-weight-6 font-16">Home /</a></router-link>
            <router-link to="/paket-umroh"><a href="#" class="text-primary font-weight-6 font-16">List Umroh /</a></router-link>
            <a href="#" class="text-dark font-weight-6 font-16"> {{ dataContent.nama }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 container-standar">
      <div class="row">

        <div class="col-md-8">

          <div class="row pl-2 pr-2">
            <div class="col-lg-12 pad-20 berita-content card shadow">
              <div class="img_umroh">
                <slick  ref="slick"   class="slickHome slickDetailUp" :options="slickOptions">
                  <div class="slick-content-detail" :key="'items.file'+index" v-for="(items, index) in slideData" >
                    <img  :src="items.file" alt />
                  </div>
                  <!-- <div class="slick-content-detail" :key="index" v-for="(items, index) in slideData" >
                    <img src="@/assets/images/background/slide-1.jpg" :alt="dataContent.slug" />
                  </div> -->
                </slick>
                <div class="overflow-img text-left hide-mobile">
                  <div class="float-left w-50">
                    <h1
                      class="text-white font-18 d-inline-block w-100 font-weight-6"
                    >{{ dataContent.nama }}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2 pl-2 pr-2">
            <div class="col-lg-6 pad-10 card shadow">
              <p class="d-block font-weight-6 mt-2 font-14 text-primary mb-4 text-center">
                Penerbangan Berangkat
              </p>
              <hr>
              <div class="text-center font-14">
                <span class="text-grey">
                    <center><img :src="productAirline.logo" class="width-20"></center>
                    <i>({{productAirline.depart_from}}) <b><i class="fa fa-angle-double-right text-primary"></i></b> ({{productAirline.depart_to}})</i>
                </span>
              </div>
            </div>
            <div class="col-lg-6 pad-10 card shadow">
              <p class="d-block font-weight-6 mt-2 font-14 text-primary mb-4 text-center">
                Penerbangan Pulang
              </p>
              <hr>
              <div class="text-center font-14">
                <span class="text-grey">
                    <center><img :src="productAirline.logo" class="width-20"></center>
                    <i> ({{productAirline.return_from}}) <b><i class="fa fa-angle-double-right text-primary"></i></b> ({{productAirline.return_to}})</i>
                </span>
              </div>
            </div>
          </div>

          <div class="row mt-2 pl-2 pr-2">

            <div class="width-100 shadow mt-2 card pl-3 pr-10 pb-10" :key="h" v-for="(hotel, h) in productHotel">
              <p class="d-block font-weight-6 mt-2 font-15 text-primary mb-4">
                <i class="fa fa-hotel"></i> Hotel {{hotel.name}}
                <span class="text-warning">
                  <i v-for="n in parseInt(hotel.star)" :key="n" class="fa fa-star"></i>
                </span>
              </p>
              <hr>
              <div class="clear font-12" v-html="hotel.description"></div>
              <hr>
              <div class="row pl-3">
                <img class="imageThumb" v-for="(img, i) in hotel.image" :src="img.file" @click="irex = i" :key="i">
              </div>
            </div>

          </div>

          <div class="row mt-2 pl-2 pr-2">
            
            <div class="width-100 shadow card pl-3 pr-10 pb-10">
              <div class="inline-block w-100" v-show="openFirst">
                <b-tabs fill>
                  <b-tab title="Detail" class="text-left" active>
                    <div class="clear" v-html="dataContent.description"></div>
                  </b-tab>
                  <b-tab title="Itinerary" class="text-left">
                    <div class="post-content pad-20 mt-10">
                      <div class="post-paragraph clear" v-html="dataContent.itinerary"></div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>

          </div>

        </div>

        <div class="col-md-4">
          <div v-show="!LoginCheck">
            <div class="card shadow overflow-hidden">
              <span class="d-block text-center w-100 font-18 text-white pad-5 font-weight-6 bg-danger" v-if="$store.state.userdata == null">
                Harap Login Terlebih Dahulu
              </span>
              <span
                class="text-warning border-bottom-2 bc-warning pt-2 pb-2 font-20 text-center d-block w-100 font-weight-6"
              >Daftar Sekarang</span>
              <div class="width-100 pl-4 pr-4 pt-3 pb-3 d-inline-block border-bottom-1 bc-warning">
                <span
                  class="text-primary d-block width-100 font-16 mb-20 font-weight-6"
                >{{ dataContent.nama }}</span>                  
                <span class="float-left width-35 text-dark font-16 mb-10 font-weight-6">Harga/Pax</span>
                <span class="float-left width-65 text-right text-danger font-16 mb-10" v-if="hargaPerPax == 0">
                  Harga tidak tersedia.
                </span>
                <span class="float-left width-65 text-right text-danger font-16 mb-10 font-weight-6" v-bind:class="{'text-decoration': dataContent.diskon != 0}" v-else>
                  IDR. {{ hargaPerPax | numeralFormat('0,0[.]00') }}
                </span>
                <div class="form-group" v-if="dataContent.diskon != 0">
                  <span class="float-left width-50 text-dark font-16 mb-10 font-weight-6">Diskon</span>
                  <span class="float-right width-50 text-right text-danger font-16 mb-10">
                    {{dataContent.diskon | numeralFormat('0,0[.]00')}}
                  </span>

                  <span class="float-left width-50 text-dark font-16 mb-10 font-weight-6">Harga Promo /Pax</span>
                  <span class="float-right width-50 text-right text-danger font-16 mb-10">
                    {{hargaPerPax - ((hargaPerPax != 0) ? dataContent.diskon : 0) | numeralFormat('0,0[.]00')}}
                  </span>
                </div>
              </div>
              <div class="width-100 pl-4 pr-4 pt-1 border-bottom-1 bc-warning pb-3 d-inline-block">
                  <div class="form-group">
                    <label for="destination" class="text-primary font-12 font-weight-6">Jadwal Keberangkatan</label>
                      <v-select class="date-icon" as="date:id:id" name="dateDepartModel" 
                        v-model="dateDepartModel" 
                        :options="dateDepart"
                        @input="changeDateDepart"
                      ></v-select>
                  </div>

                  <div class="form-group">
                    <label for="room" class="text-primary font-12 font-weight-6">Pilihan Kamar</label>
                    <select v-model="roomModel" @change="changeRoom($event)" class="form-control">
                      <option :key="index" v-for="(room, index) in roomList[0]" :value="room">{{index.charAt(0).toUpperCase()+index.slice(1)}}</option>
                    </select>
                    <span class="eror text-danger font-10">{{errors.first('roomModel')}}</span>
                  </div>

                  <div class="form-group mt-1">
                    <label for="name" class="text-primary font-12 font-weight-6">Nama Lengkap</label>
                    <input
                      type="text"
                      placeholder="Nama Lengkap"
                      v-model="name_User"
                      name="Name"
                      v-validate="'required'"
                      class="input-same-select pos-relative"
                    />
                       <span class="eror text-danger font-10">{{errors.first('Name')}}</span>
                  </div>

                  <div class="form-group">
                    <label for="name" class="text-primary font-12 font-weight-6">Nomor Hp/Wa</label>
                    <input
                      type="number"
                      placeholder="Nomor Hp/Wa"
                      v-model="phone"
                      name="phone_number"
                      v-validate="'required'"
                      class="input-same-select pos-relative"
                    />
                     <span class="eror text-danger font-10">{{errors.first('phone_number')}}</span>
                  </div>

                  <div class="group-field">
                    <label for="anyDes" class="text-primary font-12 font-weight-6">Jumlah Pendaftar</label>
                    <div class="input-group">
                      <input
                        type="number"
                        name="JumlahVisa"
                        class="form-control"
                        v-model="paxTour"
                        placeholder="Input Jumlah Pax"
                        v-validate="'min_value:1'"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2">Pax</span>
                      </div>
                    </div>
                    <span class="eror text-danger font-10">{{errors.first('JumlahVisa')}}</span>
                  </div>

                  <div class="form-group mt-3">
                    <label for="selectDp" class="text-primary font-12 font-weight-6">Down payment / DP</label>
                    <v-select
                      class="caret-icon"
                      name="selectDp"  
                      :options="sortDp"  
                      label="text"   
                      v-model="selectDp"
                      placeholder="Pilih Down Payment"
                    ></v-select>
                    <!-- <span class="eror text-danger font-10">{{errors.first('selectDp')}}</span> -->
                    <span class="d-block w-100 text-danger font-12" v-show="showTextNom">{{messageText}}</span>
                  </div>
                  <!-- <div class="form-group">
                    <label for="paymentMethod" class="text-primary font-12 font-weight-6">Metode Pembayaran</label>
                    <select v-model="paymentMethod" @change.prevent="onPaymentMethod" class="form-control">
                      <option value="cash">Tunai</option>
                      <option value="installment">Kartu Kredit</option>
                      <option value="saving">Tabungan</option>
                    </select>
                    <span class="eror text-danger font-10">{{errors.first('paymentMethod')}}</span>
                  </div>

                  <div class="form-group" v-show="showTenor">
                    <label for="tenorTabungan" class="text-primary font-12 font-weight-6">Metode Pembayaran</label>
                    <select v-model="tenorTabungan" class="form-control">
                      <option value="12">12 Bulan</option>
                      <option value="24">24 Bulan</option>
                    </select>
                    <span class="eror text-danger font-10">{{errors.first('tenorTabungan')}}</span>
                  </div> -->
              </div>                
              <div class="width-100 pl-4 pr-4 pt-3 border-bottom-1 bc-warning pb-3 d-inline-block">
                <!-- <span class="float-left width-35 text-primary font-16 mb-10 font-weight-6">
                Total DP
                </span>
                <span
                  class="float-left width-65 text-right text-danger font-16 mb-10 font-weight-6"
                >IDR. {{ paxCalculate(dataContent.dp_amount) | numeralFormat('0,0[.]00') }}</span> -->
                <span class="float-left width-35 text-primary font-16 mb-10 font-weight-6">
                Total Harga
                </span>
                <span class="float-left width-65 text-right text-danger font-12 mb-10" v-if="totalHarga == 0">
                  Harga tidak tersedia.
                </span>
                <span class="float-left width-65 text-right text-danger font-16 mb-10 font-weight-6" v-else>
                  IDR. {{ paxCalculate(totalHarga - dataContent.diskon) | numeralFormat('0,0[.]00') }}
                </span>
              </div>
                <div class="form-group pt-4 pb-4 pad-10 d-inline-block">
                <span @click.prevent="Validation()"
                  class="d-block w-70 bg-warning text-center pad-15 border-radius-5 cursor-pointer text-white"
                  >Selanjutnya</span>
                </div>
            </div>



            <div class="row">
              <div class="col-lg-12 pt-2 mt-4 pb-3">
                <div class="card shadow text-left pad-10">
                  <span
                    class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary"
                  >Terkait Umroh Lainnya</span>
                  <div class="cursor-pointer" :key="index" v-for="(items, index) in dataProduk.slice(0, 4)">
                    <div class="suggest-img" @click.prevent="changePage(items)">
                      <div class="cont-img-100">
                        <template v-if="items.images == 0">
                          <img src="@/assets/images/background/tour-1.jpg" :alt="items.slug">
                        </template>
                        <template v-else >
                          <div>
                            <img v-bind:src="chesen(items)" class="width-150" :alt="items.slug" />
                          </div>
                        </template>
                      </div>
                      <span class="title font-14 font-weight-6">
                        {{items.nama}}
                        <br />
                        <span class="text-grey font-weight-6 mt-2 font-12 d-inline-block">
                          {{getDateProduk(items.date_depart)}} - {{ items.day+' Hari' }}
                          <br>
                          <span class="text-primary font-weight-6">Real Travel</span>
                        </span>
                      </span>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
          <div v-show = LoginCheck>
             <div class="card shadow text-left pad-10">
                <span
                  class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary"
                >Review Pemesanan</span>
                <div class="form-group d-inline-block mt-4">
                  <span class="float-left">Nama Pemesan</span>
                  <span class="float-right font-weight-6 text-primary">{{ name_User }}</span>
                </div> 
                <div class="form-group d-inline-block">
                  <span class="float-left">No Telp/HP</span>
                  <span class="float-right font-weight-6 text-primary">{{ phone }}</span>
                </div> 
                <div class="form-group d-inline-block mt-4 mb-1 bg-warning-light pad-10">
                  <span class="float-left font-weight-6 text-warning font-16">Detail Pemesanan</span>
                </div>
                <div class="form-group d-inline-block pl-2 pr-2 mt-4">
                  <!-- <span class="float-left">Nama Tour</span> -->
                  <span class="float-right font-weight-6 text-primary">{{ dataContent.nama }}</span>
                </div>
                <div class="form-group d-inline-block pl-2 pr-2 mt-2">
                  <span class="float-left font-12">Harga /pax</span>
                  <span class="float-right font-12 font-weight-6 text-primary">IDR. {{ hargaPerPax - dataContent.diskon | numeralFormat('0,0[.]00')  }}</span>
                </div>

                <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left font-12">Tanggal Keberangkatan</span>
                  <span class="float-right font-12 font-weight-6 text-primary">{{ dateDepartModel }}</span>
                </div>

                <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left font-12">Jumlah Pax</span>
                  <span class="float-right font-12 font-weight-6 text-primary">
                    <span>{{ paxTour }}</span> PAX
                  </span>
                </div>

                <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left font-12">Tipe Kamar</span>
                  <span class="float-right font-12 font-weight-6 text-primary">
                    <span>{{ tipeRoom }}</span>
                  </span>
                </div>

                <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left font-12">Metode Pembayaran</span>
                  <span class="float-right font-12 font-weight-6 text-primary">
                    <span v-if="paymentMethod == 'cash'">Tunai</span>
                    <span v-if="paymentMethod == 'installment'">Kartu Kredit</span>
                    <span v-if="paymentMethod == 'saving'">Tabungan</span>
                  </span>
                </div>

                <div class="form-group d-inline-block pl-2 pr-2" v-if="paymentMethod == 'saving'">
                  <span class="float-left">Tenor</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>{{tenorTabungan}} Bulan</span>
                  </span>
                </div>

                <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left font-12">Down Payment</span>
                  <span class="float-right font-12 font-weight-6 text-primary">
                    <span v-if="selectDp.id != 4">{{selectDp.text}} x {{paxTour}}</span>
                    <span v-if="selectDp.id == 4">{{ paxCalculate(totalHarga - dataContent.diskon) | numeralFormat('0,0[.]00') }}</span>
                  </span>
                </div>
                
                <!-- <div class="form-group d-inline-block pl-2 pr-2" v-if="selectDp.id != 4">
                  <span class="float-left">Total DP</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                   {{ paxCalculate(selectDp.id) | numeralFormat('0,0[.]00') }}
                  </span>
                </div> -->
                <!-- <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left">Total DP</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                   {{ paxCalculate(dataContent.dp_amount) | numeralFormat('0,0[.]00') }}
                  </span>
                </div> -->

                <div class="form-group d-inline-block bg-danger-light pt-3 pb-3 pl-2 pr-2">
                  <span class="float-left font-weight-6">Total Harga</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                   {{ paxCalculate(totalHarga - dataContent.diskon) | numeralFormat('0,0[.]00') }}
                  </span>
                </div>

                <div class="form-group d-inline-block pt-3 pb-3 pl-2 pr-2">
                  <span
                    class="d-block w-100 mb-6 cursor-pointer bg-primary text-white text-center pad-7 border-radius-5"
                    @click.prevent="backTo()"
                  >Kembali</span>

                  <span
                    class="d-block w-100 bg-warning cursor-pointer text-white text-center pad-7 border-radius-5"
                    @click.prevent="OpenPayment()"
                  >Bayar</span>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    
    <b-modal id="modal-Success"   hide-footer no-close-on-backdrop centered title>
      <div class="col-md-12 pb-6 text-center">
        <img width="150px" class="margin-auto pb-10 pt-4" src="@/assets/images/icon/check.svg" alt />
        <span class="text-primary font-weight-6 font-18">Pesanan Anda Sedang di Proses</span>
        <br />
        <span class="text-grey font-weight-5 font-16 d-block mb-20">Silahkan lanjutkan Pembayaran</span>
         <!-- <span  @click.prevent="PostData(2)"
          class="d-block width-50 bg-primary   pt-2 pb-2 font-weight-6 text-white border-radius-5 margin-auto mb-15  cursor-pointer"
        >Bayar DP</span>
        <span   @click.prevent="PostData(3)"
          class="d-block width-50 bg-warning pt-2 pb-2 font-weight-6 text-white border-radius-5 margin-auto mb-15   cursor-pointer"
        >Bayar Lunas</span> -->
        <span @click.prevent="PostData(trasaction_inv)"
          class="d-block width-50 bg-warning pt-2 pb-2 font-weight-6 text-white border-radius-5 margin-auto mb-15   cursor-pointer"
        >Lanjutkan pembayaran</span>
      </div>
    </b-modal>
    <b-modal id="modal-Payment" scrollable    hide-footer no-close-on-backdrop centered  >
    <template v-slot:modal-title>
     <span class="d-block font-18 text-primary font-weight-7">Pilih Metode Pembayaran</span>
    </template>
        <payment :dataBinding='trasaction_inv'></payment> 
    </b-modal>
  </div>
</template>
<script>
import Slick from "vue-slick";
import payment from "@/views/components/payment_iframe.vue";
import "flatpickr/dist/flatpickr.css";
import * as moment from "moment";
import stripHtml from "string-strip-html";

export default {
  components: {
    Slick,
    // flatPickr,
    payment,
  },
  data() {
    return { 
      dataProduk:[],
      numberCount: 4,
      name_User: null, 
      LoginCheck:false,
      openFirst: true,
      tourDate: null,
        date: new Date(),
        config: {
        mode: "single",
        minDate: "today",
        dateFormat: "D d-M-Y"
      },  
      paxTour: 1, 
      phone: null, 
      email: null, 
      response:false,
      resposive:true,
      trasaction_inv:null,
      dataContent:{}, 
      slideData:[],
      // slidesToShow:null,
      currentSlide:'',
      dateDepartModel:null,
      dateDepart: [],
      id_depart:null,
      productAirline:[],
      productHotel:[],
      roomList:[],
      hargaPerPax:null,
      totalHarga:null, 
      paymentMethod:'',
      showTenor:false,
      roomModel:0,
      tipeRoom:'',
      tenorTabungan:null,
      selectDp:'',
      showTextNom:false,
      messageText:'',
      sortDp:[
        { "id": 500000, text: Number(500000).toLocaleString() },
        { "id": 3500000, text: Number(3500000).toLocaleString() },
        { "id": 7000000, text: Number(7000000).toLocaleString() },
        { "id": 10000000, text: Number(10000000).toLocaleString() },
        { "id": 4, text: 'Lunas'},
      ],
      slickOptions: {
        slidesToShow: 1,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false, 
        arrows: false, 
      },
    };
  },
  mounted() {
    this.$store.commit("SET_BG", false);
    this.localSelectDepart =  JSON.parse(localStorage.getItem('response-date-depart'))
    this.getUserData()
    this.getDataDetail()
    this.getListProduk()
    // this.onPaymentMethod()
    // this.changeDateDepart()
    if (window.screen.width <= 800) {
      this.responsive = false 
      // console.log('mobile');
      this.respon = true
      
    }else{
        this.responsive = true 
        this.respon = false
      //  console.log('desktop');
    }
  },
  created() {
    setTimeout(() => {
      moment.locale('ID'); 
      // this.dateDepartModel = moment(this.dataContent.date_depart[0].date).format('LL');
      this.changeDateDepart(this.dateDepartModel)
      this.paymentMethod = 'cash'
      this.tipeRoom = 'Quad'
    }, 1000);
  },
  watch: {
    slideData() {
      let currIndex = this.$refs.slick.currentSlide() 
      this.$refs.slick.destroy()
      this.$nextTick(() => {
        this.$refs.slick.create()
        this.$refs.slick.goTo(currIndex, true)
      })      
    },
    selectDp(){
      if (this.selectDp.id != '') {
        this.showTextNom = false;
        this.messageText = '';
      } else {
        this.showTextNom = true;
        this.messageText = true;        
      }
    }
  },
  methods: {
    getUserData(){
      if (this.$store.state.userdata != null) {
        this.name_User = this.$store.state.userdata.name;
        this.phone = this.$store.state.userdata.phone;
      }
    },
    // Validation() {   
    //   if (this.$store.state.userdata !== null) {      
    //     this.validator();
    //     this.LoginCheck = true;
    //   } else {
    //     this.$bvModal.show("modal-login");
    //   } 
    // },
    // validator(){
    //   this.$validator.validateAll().then(valid => {
    //   if (valid) { 
    //     this.LoginCheck = true;
    //   }
    //     this.LoginCheck = true;
    //   });
    // },
    Validation() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          if (this.selectDp == '') {
            this.messageText = '*Minimal Dp Rp 500.000';
            this.showTextNom = true;
          } else if(this.hargaPerPax == 0){
            this.$swal('', '<span class="font-weight-5 font-14 text-warning">Harga belum tersedia. Silahkan pilih kembali tipe kamar.</span>', 'warning');
          } else {
            if (this.$store.state.userdata !== null) {
              this.LoginCheck = true;         
            } else {
              this.$bvModal.show("modal-login");
            }
          }
        }
      });
    },
    paxCalculate(neVal){
      if(this.paxTour  >  0 ){
      
        return neVal * this.paxTour 
      }else{
          return neVal
      }      
    },    
    changeDateDepart(date){
      let dateVal = moment(date, 'LL').format('YYYY-MM-DD');
      this.roomList = []
      for (let i = 0; i < this.dataContent.date_depart.length; i++) {
        if (dateVal == this.dataContent.date_depart[i].date) {
          this.roomList.push({
            quad: this.dataContent.date_depart[i].quad,
            triple: this.dataContent.date_depart[i].triple,
            double: this.dataContent.date_depart[i].double
          });
          this.totalHarga = this.dataContent.date_depart[i].quad
          this.hargaPerPax = this.dataContent.date_depart[i].quad
          this.roomModel = this.dataContent.date_depart[i].quad
          this.tipeRoom = 'Quad'
          this.id_depart = this.dataContent.date_depart[i].id_depart
        }
      }
    },
    changeRoom(e){
      this.totalHarga = e.target.value
      this.hargaPerPax = e.target.value
      this.tipeRoom = event.target.options[event.target.options.selectedIndex].text
    },  
    onPaymentMethod(e){
      if (e.target.value == 'saving') {
          this.showTenor = true
          this.tenorTabungan = '12'
      } else {
          this.showTenor = false
          this.tenorTabungan = ''
      }
    },
    OpenPayment(){
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "umroh"); 
      param.append("call", "book_umroh");
      param.append("partner_id", this.$store.state.userdata.partner_id);  
      param.append("id_depart", this.id_depart);  
      param.append("name", this.name_User);
      param.append("phone", this.phone);
      param.append("pax", this.paxTour);
      param.append("type_room", this.tipeRoom);
      param.append("room_price", this.totalHarga - this.dataContent.diskon);
      param.append("payment_type", this.paymentMethod);
      param.append("tenor", '');      
      if(this.selectDp.id != 4){
        param.append("dp_amount", this.selectDp.id);
        param.append("paid_off", 0);
      }else{
        param.append("paid_off", 1);
      }
      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
            headers: new Headers({
              accept: "application/json",
              "Content-Type": "multipart/form-data, text/plain"
            })
          })
        .then(response => {
          let res = response
          // this.$bvModal.hide("modal-Success");
          if(res.status == 200){ 
            this.trasaction_inv = res.data.data.invoice_number
            setTimeout(() => {              
              this.$bvModal.show("modal-Success");
            }, 500);
          }
      });
    },
    getDataDetail(){ 
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "umroh");
      param.append("product_id", "");  
      param.append("slug", this.$route.params.slug);  
      param.append("call", "list_umroh");

      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain"
          })
        })
        .then(response => {
          let res = response.data.data;
          this.dataContent = res 
          this.slideData = res.images_detail
          this.hargaPerPax = res.amount
          // this.selectDateDepart = res.date_depart
          this.productAirline = res.product.airline[0]
          this.productHotel = res.product.hotel
          for (let i = 0; i < res.date_depart.length; i++) {
            moment.locale('ID'); 
            this.dateDepart.push(moment(res.date_depart[i].date).format('LL'));
          }
          this.dateDepartModel = moment(this.localSelectDepart).format('LL')
        })
        .catch(error => {
          error;
        });
     
    },    
    getListProduk() {
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "umroh");
      param.append("keyword", ''); 
      param.append("call", "list_umroh"); 
      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain"
          })
        })
        .then(response => {
          let res = response.data;
          this.dataparse = res
          for (let i = 0; i < res.data.length; i++) {
            this.dataProduk.push(res.data[i])
          }
        })
        .catch(error => {
          error;
        });
    },
    // parseMethod(){
    //     this.dataProduk = this.dataparse.data.slice(0, this.numberCount)
    // },
    getDateDepart(val){
      moment.locale('ID'); 
      return moment(val).format('LL')
    },
    getDateProduk(val){
      moment.locale('ID'); 
      return moment(val).format('LL')
    },
    chesen(val){
      return val.image
    },
    changePage(NewVal){
      moment.locale('ID'); 
      this.localSelectDepart = moment(NewVal.date_depart)
      localStorage.setItem("response-date-depart", JSON.stringify(NewVal.date_depart));
      this.$router.push('/paket-umroh/detail/'+NewVal.slug+'/'+NewVal.date_depart)
      this.totalHarga = NewVal.amount
      this.getDataDetail()
      // this.getListProduk()
    },
    contentItinerary(val){
      return stripHtml(val)
    },
    PostData(newVal){
      window.open("https://api.realtravel.co.id/transaction/payment?type=package&invoice_number="+newVal, '_blank');
    },
    // PostData(){
    //   let param = new FormData();
    //   param.append("api_key", "2222");
    //   param.append("format", "");
    //   param.append("ws", "umroh"); 
    //   param.append("call", "book_umroh");
    //   param.append("partner_id", this.$store.state.userdata.partner_id);  
    //   param.append("id_depart", this.id_depart);  
    //   param.append("name", this.name_User);
    //   param.append("phone", this.phone);
    //   param.append("pax", this.paxTour);
    //   param.append("type_room", this.tipeRoom);
    //   param.append("room_price", this.totalHarga);
    //   param.append("payment_type", this.paymentMethod);
    //   param.append("tenor", '');      
    //   if(this.selectDp.id != 4){
    //     param.append("dp_amount", this.selectDp.id);
    //     param.append("paid_off", 0);
    //   }else{
    //     param.append("paid_off", 1);
    //   }
    //   this.axios
    //     .post("https://apitiket.realtravel.co.id/ws", param, {
    //         headers: new Headers({
    //           accept: "application/json",
    //           "Content-Type": "multipart/form-data, text/plain"
    //         })
    //       })
    //     .then(response => {
    //       let res = response
    //       this.$bvModal.hide("modal-Success");
    //       if(res.status == 200){ 
    //         this.trasaction_inv = res.data.data.invoice_number
    //         setTimeout(() => {
    //           this.$bvModal.show("modal-Payment");
    //         }, 500);
    //       }
    //   });
    // },
    checkresponsive() {
      this.openFirst = false;
      this.responsive = true;
    },
    backresponsive() {
      this.openFirst = true;
      this.responsive = false;
    },
    backTo() {
      this.LoginCheck = false;
    }
  },
};
</script>