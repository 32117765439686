<template>
  <div class="col-lg-12 mb-10">
    <div class="card shadow text-left">
      <iframe id="frame" v-bind:src="'https://api.realtravel.co.id/transaction/payment?type=package&invoice_number='+dataBinding"
        frameborder="0"
        width="100%" 
        height="600"></iframe>
    </div>
  </div>
</template>
<script>
export default { 
    props:{
    dataBinding:String,
    },
    data() {
        return {
            
        }
    },
    mounted() {  
 
    },
    methods: {
        dataNumber(){
            // console.log(this.dataBinding + id_Val)
            // if(id_Val !== 0){
            //       location.assign('https://api.realtravel.co.id/transaction/payment/duitku_inquiry/product?invoice_number='+this.dataBinding+'&channel_id='+id_Val)

            // }else{
            //      location.assign('https://api.realtravel.co.id/transaction/payment/transfer?type=product&invoice_number='+this.dataBinding)
            // }
          // location.assign('https://api.realtravel.co.id/transaction/payment?type=package&invoice_number='+this.dataBinding)
        }
    },
}
</script>